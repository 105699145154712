import {
    ADD_PROJECT_INFO
} from '../actions';

const INIT_STATE = {
    projectItems:null
};

const projectInfo =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_PROJECT_INFO:
			return { ...state, projectItems:action.payload};
		default:
			return {...state}
	}

}

 export default projectInfo