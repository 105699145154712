import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import productInfo from "./projectInfo/reducer";
import quantityInfo from "./quantityInfo/reducer";
import doorInfo from "./doorInfo/reducer";
import homeInfo from "./homeInfo/reducer";
import authUser from './auth/reducer';
import addDetailsInfo from './addDetailInfo/reducer'

const reducers = combineReducers({
  menu,
  settings,
  productInfo,
  quantityInfo,
  doorInfo,
  homeInfo,
  authUser,
  addDetailsInfo,
  
});

export default reducers;
