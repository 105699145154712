import { ADD_DOOR_INFO } from "../actions";

const INIT_STATE = {
  doorItems: null,
};

const doorInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DOOR_INFO:
      return { ...state, doorItems: action.payload };
    default:
      return { ...state };
  }
};

export default doorInfo;
