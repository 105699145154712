import {
    ADD_QUANTITY_INFO
} from '../actions';


export const addQuantityInfo = (item,dispatch) => {
     dispatch(addQuantity(item));
};

export const addQuantity = (item) => {
    return {
        type: ADD_QUANTITY_INFO,
        payload: item
    }
};