export const CHANGE_LOCALE = "CHANGE_LOCALE";

export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export const ADD_PROJECT_INFO = "ADD_PROJECT_INFO";
export const ADD_QUANTITY_INFO = "ADD_QUANTITY_INFO";
export const ADD_DOOR_INFO = "ADD_DOOR_INFO";
export const ADD_DETAILS_INFO = "ADD_DETAILS_INFO";
export const ADD_HOME_INFO = "ADD_HOME_INFO";

export * from "./auth/action";
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./projectInfo/actions";
export * from "./quantityInfo/actions";
export * from "./doorInfo/actions";
export * from "./addDetailInfo/actions"
export * from "./homeInfo/actions";
