import { ADD_HOME_INFO } from "../actions";

const INIT_STATE = {
  homeItems: null,
};

const homeInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_HOME_INFO:
      return { ...state, homeItems: action.payload };
    default:
      return { ...state };
  }
};

export default homeInfo;
