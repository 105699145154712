import { ADD_DETAILS_INFO } from '../actions';

const INIT_STATE = {
	detailsItems: null
};

const detailInfo = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_DETAILS_INFO:
			return { ...state, detailItems: action.payload };
		default:
			return { ...state };
	}
};

export default detailInfo;
