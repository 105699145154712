import {
    ADD_PROJECT_INFO
} from '../actions';


export const addProjectInfo = (item,dispatch) => {
     dispatch(addProject(item));
};

export const addProject = (item) => {
    return {
        type: ADD_PROJECT_INFO,
        payload: item
    }
};