import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from "./Integration/redux/store"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';

// Sentry.init({
//   dsn: "https://9ba72a4a07494d74bfbb6cca35b5b03a@o1087835.ingest.sentry.io/6101906",
//   integrations: [
//     new CaptureConsole(
//       {
//         // array of methods that should be captured
//         // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
//         levels: ['error','info']
//       })
//   ],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.7,
//   environment: 'development',
// });

//const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));
const Application = React.lazy(() => import('./Application' ));
ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      {/* <App /> */}
      <Application/>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
