import {
    ADD_QUANTITY_INFO
} from '../actions';

const INIT_STATE = {
    quantityItems:null
};

const quantityInfo =  (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_QUANTITY_INFO:
			return { ...state, quantityItems:action.payload};
		default:
			return {...state}
	}

}

 export default quantityInfo